import React, { useEffect, useState } from "react";
import axios from "axios";
import styles from "./authenticatedTemplateComponent.module.scss";
import { useIsAuthenticated } from "@azure/msal-react";
import HorizonLogo from "../../assets/horizon-logo.png";
import { SignOutButton } from "../sign-out-button/SignOutButton";
import { useMsal } from "@azure/msal-react";
import { horizonOnlineScopes } from "../../auth/authConfig";
//import scopes from "../../assets/scope.json";
import {
  HZ_AUTH_COOKIE_KEY,
  RETURN_URL_QUERY_KEY,
  LOGIN_PATH,
  HZ_SCOPE_COOKIE_KEY
} from "../constants";

const AuthenticatedTemplateComponent = () => {
  const { instance, accounts } = useMsal();
  const [isLoading, setIsLoading] = useState(true);
  const [scopes, setScopes] = useState([]);
  const isAuthenticated = useIsAuthenticated();

  useEffect(() => {
    (async () => {
      setIsLoading(true);

      if (window.location.href.includes("/logout")) {
        await handleLogout();
        return;
      }

      if (!isAuthenticated) {
        return;
      }

      const response = await instance
        .acquireTokenSilent({
          ...horizonOnlineScopes,
          account: accounts[0]
        })
        .then((res) => res);

      document.cookie = `${HZ_AUTH_COOKIE_KEY}=${response.accessToken}; path=/;`;

      await getScopes(response.accessToken);

      setIsLoading(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  const handleRedirect = async (id) => {
    const urlParams = new URLSearchParams(window.location.search);

    let returnURL =
      urlParams && urlParams?.get(RETURN_URL_QUERY_KEY)
        ? urlParams.get(RETURN_URL_QUERY_KEY).toString()
        : null;

    if (!returnURL) {
      window.location.href = `${LOGIN_PATH}/${id}?next=`;
    } else if (!returnURL.includes("oauth-authorized")) {
      window.location.href = `${LOGIN_PATH}/${id}?next=${returnURL}`;
    }
  };

  const getScopes = async (token) => {
    try {
      const result = await axios.get("/scopes", {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });

      if (result?.data) {
        if (result.data.length === 1) {
          // document.cookie = `${HZ_SCOPE_COOKIE_KEY}=${result.data[0].id}; path=/;`;
          await handleRedirect(result.data[0].id);
        } else {
          setScopes(result.data);
        }
      }
    } catch (err) {
      console.error(err);
    }
  };

  const scopeClick = async (scopeID) => {
    document.cookie = `${HZ_SCOPE_COOKIE_KEY}=${scopeID}; path=/;`;
    await handleRedirect(scopeID);
  };

  const handleLogout = async () => {
    document.cookie = `${HZ_AUTH_COOKIE_KEY}; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
    document.cookie = `${HZ_SCOPE_COOKIE_KEY}; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;

    // handle auth redired/do all initial setup for msal
    await instance
      .handleRedirectPromise()
      .then(async (authResult) => {
        await instance.logoutRedirect({ postLogoutRedirectUri: "/login" });
      })
      .catch((err) => {
        // TODO: Handle errors
        console.log(err);
      });
  };

  return (
    <>
      {isLoading ? (
        <div className={styles["splash-screen"]}>
          <div className={styles["logo-container"]}>
            <img
              className={styles["horizon-logo"]}
              src={HorizonLogo}
              alt="Horizon Customer Data Platform"
            />
          </div>
        </div>
      ) : (
        <div className={styles["auth-container"]}>
          <center>
            <div>
              <img
                className={styles["horizon-logo"]}
                src={HorizonLogo}
                alt="Horizon Customer Data Platform"
              />
            </div>
            <div style={{ minHeight: "8rem" }}>
              {scopes &&
                scopes?.length > 1 &&
                scopes.map((scope) => {
                  return (
                    <button
                      key={scope.id}
                      onClick={() => scopeClick(scope.id)}
                      className={styles["scope-btn"]}
                    >
                      <div style={{ width: "100%" }}>
                        <div className={styles["scope-client-text"]}>
                          {scope.clientFullName}
                        </div>
                        <div className={styles["scope-scope-text"]}>
                          {scope.name}
                        </div>
                      </div>
                    </button>
                  );
                })}
            </div>
            <div>
              <SignOutButton />
            </div>
          </center>
        </div>
      )}
    </>
  );
};

export default AuthenticatedTemplateComponent;
