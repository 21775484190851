import React from "react";
import { useMsal } from "@azure/msal-react";
import styles from "./signOutButton.module.scss";
import image from "../../assets/ms-icon.png";

/**
 * Renders a sign out button
 */
export const SignOutButton = () => {
  const { instance } = useMsal();

  const handleLogout = () => {
    instance.logoutRedirect({
      postLogoutRedirectUri: "/login"
    });

    document.cookie =
      "hz-auth=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
  };

  return (
    <div
      role="button"
      className={styles["sign-out-btn"]}
      onClick={() => handleLogout()}
    >
      <span className={`material-icons-outlined ${styles["sign-out-img"]}`}>
        logout
      </span>
      <span className={styles["sign-out-text"]}>Logout</span>
    </div>
  );
};
