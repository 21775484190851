import React, { ReactDOM, useEffect } from "react";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { loginRequest, horizonOnlineScopes } from "../../auth/authConfig";
import image from "../../assets/ms-icon.png";
import styles from "./signInButton.module.scss";

/**
 * Renders a drop down button with child buttons for logging in with a popup or redirect
 * Note the [useMsal] package
 */

export const SignInButton = () => {
  const { instance, accounts } = useMsal();
  const isAuthenticated = useIsAuthenticated();

  const handleLogin = () => {
    instance.loginRedirect(loginRequest).catch((e) => {
      console.log(e);
    });
  };

  return (
    <div
      role="button"
      className={styles["login-btn"]}
      onClick={() => handleLogin()}
    >
      <img className={styles["ms-icon"]} src={image} alt="Microsoft icon"></img>
      <span className={styles["login-text"]}>Log in with Microsoft</span>
    </div>
  );
};
