import { LogLevel } from "@azure/msal-browser";

export const msalConfig = {
  auth: {
    clientId: "d1adacf7-04aa-4279-bdfc-2554f9c442de",
    authority:
      "https://login.microsoftonline.com/1edab52f-c443-4f3c-8a2f-0823345d8ca7/",
    redirectUri: "/signin-oidc"
  },
  cache: {
    cacheLocation: "localStorage",
    storeAuthStateInCookie: true
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            return;
          case LogLevel.Info:
            return;
          case LogLevel.Verbose:
            return;
          case LogLevel.Warning:
            return;
          default:
            break;
        }
      }
    }
  }
};

export const horizonOnlineScopes = {
  scopes: ["api://d1adacf7-04aa-4279-bdfc-2554f9c442de/User_access"]
};

export const loginRequest = {
  scopes: ["User.Read"]
};

export const graphConfig = {
  graphMeEndpoint: "https://graph.microsoft.com/v1.0/me"
};
