import React, { useEffect } from "react";
import styles from "./unauthenticatedTemplateComponent.module.scss";
import { SignInButton } from "../sign-in-button/SignInButton";
import HorizonLogo from "../../assets/Horizon-logo-RGB-primary-reverse.png";
import { HZ_AUTH_COOKIE_KEY, HZ_SCOPE_COOKIE_KEY } from "../constants";

const UnauthenticatedTemplateComponent = () => {
  useEffect(() => {
    //just to be sure we're starting fresh.
    document.cookie = `${HZ_AUTH_COOKIE_KEY}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
    document.cookie = `${HZ_SCOPE_COOKIE_KEY}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
  }, []);

  return (
    <>
      <div className={styles["unauth-container"]}>
        <center>
          <img
            className={styles["horizon-logo"]}
            src={HorizonLogo}
            alt="Horizon Customer Data Platform"
          />
          <SignInButton />
        </center>
      </div>
    </>
  );
};

export default UnauthenticatedTemplateComponent;
